<template>
  <div class="add-event add-template">
    <g-background>
      <page-header>
        <h1>{{ title }}</h1>
      </page-header>
      <page-body>
        <b-form class="member-type-form">
          <b-form-group :label="translations.tcCategoryType" class="font-style-2 mb-0">
            <!-- First Dropdown for Categories -->
            <b-form-select
              v-model="selectedCategory"
              :options="categoryOptions"
              class="form-control g-select"
              placeholder="Select a category"
              @change="onCategoryChange"
              v-b-tooltip.bottom="selectedCategory ? selectedCategory.ItemName : 'Select a category from the list'"
            ></b-form-select>
          </b-form-group>

          <!-- Second Dropdown for Items based on selected Category -->
          <b-form-group :label="translations.tcEvent" class="font-style-2 mb-0">
            <b-form-select
              v-model="selectedEvent"
              :options="eventOptions"
              class="form-control g-select"
              placeholder="Select an item"
              @change="onEventChange"
              v-b-tooltip.bottom="selectedEvent ? selectedEvent.EventName : 'Select an event from the list'"
            ></b-form-select>
          </b-form-group>

          <!-- Third Dropdown for distribution And Placement based on selected Item -->
          <b-form-group
            :label="translations.tcDistributionPlacementType"
            class="font-style-2 mb-0"
            v-if="selectedEvent && distributionAndPlacementOptions.length > 0"
          >
            <b-form-select
              v-model="selectedSubItem"
              :options="distributionAndPlacementOptions"
              class="form-control g-select w-270"
              placeholder="Select a subitem"
              v-b-tooltip.bottom="selectedSubItem ? selectedSubItem.EventName : 'Select an event from the list'"
            ></b-form-select>
          </b-form-group>
        </b-form>

        <b-form class="member-type-form">
          <b-form-group :label="translations.tcEventTitle" class="font-style-2 mb-0">
            <b-form-input
              :value="eventTitle"
              @input="handleInput"
              :state="eventTitle !== null && eventTitle.trim() !== ''"
              id="event-input"
              :placeholder="translations.tcEventTitle"
            ></b-form-input>
          </b-form-group>
        </b-form>
        <b-form class="member-type-form">
          <b-form-group :label="translations.tcMemberType" class="font-style-2 mb-0">
            <b-form-select
              v-model="member"
              :state="member !== null"
              :options="memberType"
              class="form-control g-select"
            ></b-form-select>
          </b-form-group>
        </b-form>
        <hr />
        <section class="form-block">
          <div class="h-style-1">{{ translations.tcLocation }}</div>
          <div>
            <b-form-radio-group
              id="radios-location-type"
              v-model="locationTypeSelected"
              :options="locationTypeOptions"
              button-variant="outline-primary"
              name="adios-location-type"
              size="sm"
              buttons
            ></b-form-radio-group>
          </div>
          <div id="loc-physical" v-if="locationTypeSelected === 'physical'">
            <p class="mb-3">{{ locationDisplay }}</p>
            <b-form class="event-type-form">
              <location-search
                @location-select="handleLocationSelect"
                :cnt_key="this.userCountryKey"
                :i18n="translations.components"
              />
              <b-button
                variant="primary"
                class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0 mx-0 mt-4 mt-lg-0 mx-lg-4"
                @click="addLocation"
                >{{ translations.tcAddNewLocation }}
              </b-button>
            </b-form>
          </div>
          <div id="loc-virtual" v-if="locationTypeSelected === 'virtual'">
            <p class="mb-3">{{ locationVirtualDisplay }}</p>
            <b-form class="event-type-form">
              <location-virtual-search
                @location-virtual-select="handleVirtualLocationSelect"
                :cnt_key="this.userCountryKey"
                :i18n="translations.components"
              />
              <b-button
                variant="primary"
                class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0 mx-0 mt-4 mt-lg-0 mx-lg-4"
                @click="addLocationVirtual"
                >{{ translations.tcAddNewVirtualLocation }}
              </b-button>
            </b-form>
          </div>
        </section>
        <hr />
        <section class="form-block">
          <div class="h-style-1">{{ translations.tcCoordinator }}</div>
          <div class="bv-example-row">
            <b-row class="text-center">
              <b-col cols="4">
                <model-select
                  class="form-control"
                  :options="coordinators"
                  v-model="coordinator"
                  :placeholder="translations.tcSelectCoordinator"
                >
                </model-select>
              </b-col>
            </b-row>
          </div>
        </section>
        <hr />
        <section class="form-block">
          <b-form>
            <div class="h-style-1" v-if="recurrenceType === 'multiple' || recurrenceType === null">
              {{ translations.tcDateRecurrence }}
            </div>
            <b-form-checkbox
              type="checkbox"
              class="checkbox-ml"
              v-model="isMultiDayEvent"
              v-if="recurrenceType === null"
            >
              {{ translations.tcCheckIfRecurring }}
            </b-form-checkbox>
            <b-form-group
              :label="isMultiDayEvent ? translations.tcStartEndDate : translations.tcDate"
              class="font-style-2 mt-3 mb-0"
            >
              <div id="dpContainer" class="dp-container">
                <div id="dpStart" class="dp-startend">
                  <b-form-datepicker
                    id="startDate"
                    v-model="startEndDate.startDate"
                    @input="handleFrequencyOnDateChange"
                    class="mb-2 contact_date"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    :locale="prefCulture"
                    :label-help="translations.tcCalHelp"
                    :label-no-date-selected="translations.tcDatePickerPlaceholder"
                    :placeholder="translations.tcDatePickerPlaceholder"
                    :show-decade-nav="showDecadeNav"
                    :hide-header="hideHeader"
                    size="sm"
                  >
                    <template #button-content style="padding: 12px">
                      <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                    </template>
                  </b-form-datepicker>
                </div>
                <div id="dpEnd" class="dp-startend">
                  <b-form-datepicker
                    id="endDate"
                    v-model="startEndDate.endDate"
                    @input="handleFrequencyOnDateChange"
                    class="mb-2 contact_date"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    :locale="prefCulture"
                    :label-help="translations.tcCalHelp"
                    :label-no-date-selected="translations.tcDatePickerPlaceholder"
                    :placeholder="translations.tcDatePickerPlaceholder"
                    :show-decade-nav="showDecadeNav"
                    :hide-header="hideHeader"
                    size="sm"
                    v-if="isMultiDayEvent"
                  >
                    <template #button-content style="padding: 12px">
                      <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                    </template>
                  </b-form-datepicker>
                </div>
              </div>
            </b-form-group>
            <b-form-group :label="translations.tcStartTimeEndTime" class="font-style-2 mb-0">
              <div id="dpContainer" class="dp-container">
                <div id="dpStart" class="dp-startend">
                  <b-form-timepicker
                    v-model="startTime"
                    minutes-step="5"
                    :locale="prefCulture"
                    :label-close-button="translations.tcClose"
                    :label-no-time-selected="translations.tcNoTimeSelected"
                    reset-button
                  >
                  </b-form-timepicker>
                </div>
                <div id="dpStart" class="dp-startend">
                  <b-form-timepicker
                    v-model="endTime"
                    minutes-step="5"
                    :locale="prefCulture"
                    :label-close-button="translations.tcClose"
                    :label-no-time-selected="translations.tcNoTimeSelected"
                    reset-button
                  >
                  </b-form-timepicker>
                </div>
              </div>
            </b-form-group>
            <div class="mt-2 mb-1">
              <p v-if="isMultiDayEvent">{{ translations.tcSelectFrequency }}:</p>
              <div v-if="recurrenceType == 'multiple' || (recurrenceType == null && isMultiDayEvent)">
                <b-button-group>
                  <b-button ref="once" :variant="swap._onceshowstate" @click="changereoccurstate('once')">
                    {{ translations.tcOnce }}
                  </b-button>
                  <b-button
                    ref="daily"
                    :variant="swap._dailyshowstate"
                    v-if="isDailyVisible"
                    @click="changereoccurstate('daily')"
                  >
                    {{ translations.tcDaily }}
                  </b-button>
                  <b-button
                    ref="weekly"
                    v-if="isWeeklyVisible"
                    :variant="swap._weekshowstate"
                    @click="changereoccurstate('weekly')"
                  >
                    {{ translations.tcWeekly }}
                  </b-button>
                  <b-button
                    ref="monthly"
                    v-if="isMonthlyVisible"
                    :variant="swap._monthshowstate"
                    @click="changereoccurstate('monthly')"
                  >
                    {{ translations.tcMonthly }}
                  </b-button>
                  <b-button
                    ref="yearly"
                    v-if="isYearlyVisible"
                    :variant="swap._yearshowstate"
                    @click="changereoccurstate('yearly')"
                  >
                    {{ translations.tcYearly }}
                  </b-button>
                </b-button-group>

                <!-- Recur Daily Options -->
                <b-form-group class="form-element every-week-on" v-show="dailyshowstate && isDailyVisible" ref="daily">
                  <div class="flex-row">
                    <span>{{ translations.tcEvery }}</span>
                    <b-form-input
                      v-model="rSettings.config.daily.every"
                      @keypress="restrictToNumber"
                      class="every-input"
                    ></b-form-input>
                    <span>{{ translations.tcDayss }}</span
                    ><!-- tcDayss is not a typo -->
                  </div>
                </b-form-group>

                <!-- Recur Weekly Options -->
                <b-form-group class="form-element every-week-on" v-show="weekshowstate && isWeeklyVisible" ref="weekly">
                  <div class="flex-row">
                    <span>{{ translations.tcEvery }}</span>
                    <b-form-input
                      v-model="rSettings.config.weekly.every"
                      @keypress="restrictToNumber"
                      class="every-input"
                    >
                    </b-form-input>
                    <span>{{ translations.tcWeeksOn }}</span>
                    <b-form-checkbox-group id="checkbox-group-2" name="flavour-2">
                      <b-form-checkbox v-for="day in days" :value="day.value" :key="day.value" v-model="weeklyDay">
                        <span>{{ day.text }}</span>
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </div>
                </b-form-group>
                <!-- Recur Monthly Option 1 -->
                <b-form-group
                  class="form-element every-week-on"
                  v-show="monthshowstate && isMonthlyVisible"
                  ref="monthly"
                >
                  <div class="flex-row">
                    <b-form-radio
                      @change="clearType"
                      v-model="rSettings.config.monthly.type"
                      value="type_1"
                      name="option"
                      checked="checked"
                    ></b-form-radio>
                    <span>{{ translations.tcDay }}</span>
                    <b-form-input
                      v-model="rSettings.config.monthly.mtype_1.day"
                      @keypress="restrictToNumber"
                      class="mx-2 every-input"
                    ></b-form-input>
                    <span>{{ translations.tcOfEvery }}</span>
                    <b-form-input
                      v-model="rSettings.config.monthly.mtype_1.months"
                      @keypress="restrictToNumber"
                      class="mx-2 every-input"
                    ></b-form-input>
                    <span>{{ translations.tcMonths }}</span>
                  </div>
                </b-form-group>
                <!-- Recur Monthly Option 2 -->
                <b-form-group
                  class="form-element every-week-on"
                  v-show="monthshowstate && isMonthlyVisible"
                  ref="monthly"
                >
                  <div class="flex-row">
                    <b-form-radio
                      @change="clearType"
                      v-model="rSettings.config.monthly.type"
                      value="type_2"
                      name="option"
                    >
                    </b-form-radio>
                    <span>{{ translations.tcThe }}</span>
                    <b-form-select
                      v-model="rSettings.config.monthly.mtype_2.when"
                      :options="ordinal"
                      class="form-control g-select ml-3"
                    ></b-form-select>
                    <b-form-select
                      v-model="rSettings.config.monthly.mtype_2.weekday"
                      :options="days"
                      class="form-control g-select mx-2"
                    ></b-form-select>
                    <span>{{ translations.tcOfEvery }}</span>
                    <b-form-input
                      v-model="rSettings.config.monthly.mtype_2.months"
                      class="mx-2 every-input"
                    ></b-form-input>
                    <span>{{ translations.tcMonths }}</span>
                  </div>
                </b-form-group>

                <!-- Recur Every Year -->
                <b-form-group class="form-element every-week-on" v-show="yearshowstate && isYearlyVisible" ref="yearly">
                  <div class="flex-row">
                    <span>{{ translations.tcRecurEvery }}</span>
                    <b-form-input
                      v-model="rSettings.config.yearly.years"
                      class="every-input"
                      @keypress="restrictToNumber"
                    ></b-form-input>
                    <span>{{ translations.tcYear }}</span>
                  </div>
                </b-form-group>
                <!-- First option for Yearly -->
                <b-form-group class="form-element every-week-on" v-show="yearshowstate && isYearlyVisible" ref="yearly">
                  <div class="flex-row">
                    <b-form-radio
                      v-model="rSettings.config.yearly.type"
                      id="_option2"
                      name="_option"
                      value="type_1"
                      @change="clearTypeYear"
                    ></b-form-radio>
                    <span for="_option2">{{ translations.tcOn }}</span>
                    <b-form-select
                      v-model="rSettings.config.yearly.ytype_1.month"
                      :options="months"
                      class="form-control g-select mx-2 ml-4"
                    ></b-form-select>
                    <b-form-input
                      v-model="rSettings.config.yearly.ytype_1.day"
                      class="every-input"
                      @keypress="restrictToNumber"
                    ></b-form-input>
                  </div>
                </b-form-group>

                <!-- Second option for Yearly -->
                <b-form-group class="form-element every-week-on" v-show="yearshowstate && isYearlyVisible" ref="yearly">
                  <div class="flex-row">
                    <b-form-radio
                      v-model="rSettings.config.yearly.type"
                      id="_option1"
                      name="_option"
                      value="type_2"
                      @change="clearTypeYear"
                    ></b-form-radio>
                    <span for="_option1">{{ translations.tcOnThe }}</span>
                    <b-form-select
                      v-model="rSettings.config.yearly.ytype_2.when"
                      :options="ordinal"
                      class="form-control g-select"
                    ></b-form-select>
                    <b-form-select
                      v-model="rSettings.config.yearly.ytype_2.weekday"
                      :options="days"
                      class="form-control g-select mx-2"
                    ></b-form-select>
                    <span>{{ translations.tcOf }}</span>
                    <b-form-select
                      v-model="rSettings.config.yearly.ytype_2.month"
                      :options="months"
                      class="form-control g-select mx-2"
                    ></b-form-select>
                  </div>
                </b-form-group>
              </div>
            </div>
          </b-form>
        </section>
        <section class="form-buttons">
          <b-button
            v-if="isEditMode === null"
            @click="SaveMeetings"
            variant="primary"
            class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0"
          >
            {{ translations.tcSave }}
          </b-button>
          <b-button
            v-if="isEditMode !== null"
            variant="primary"
            class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0"
            @click="UpdateMeetings"
          >
            {{ translations.tcUpdate }}
          </b-button>
          <b-button variant="tertiary" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0" @click="cancel">
            {{ translations.tcCancel }}
          </b-button>
        </section>
      </page-body>
    </g-background>
    <add-meeting-location @locationSelectionMade="applyNewLocation($event)" :i18n="translations" />
    <add-meeting-location-virtual
      @locationVirtualSelectionMade="applyNewLocationVirtual($event)"
      :i18n="translations"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapState } from 'vuex'
import AddMeetingLocation from '@/components/AddMeetingLocation.vue'
import AddMeetingLocationVirtual from '@/components/AddMeetingLocationVirtual.vue'
import { calendarMixin } from '@/mixins/calendarMixin'
import calendarService from '../../../services/calendarService'
import CustomeLocationSearch from '@/components/CustomeLocationSearch.vue'
import LocationSearch from '@/components/calendar/calendar-search/LocationSearch.vue'
import LocationVirtualSearch from '@/components/calendar/calendar-search/LocationVirtualSearch.vue'
import constants from '@/json/data.json'
import date_data from '@/json/date.json'
import gBackground from '@/components/gBackground.vue'
import { Loader } from '@/mixins/Loader'
import { ModelSelect } from 'vue-search-select'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import SearchableDropdown from '@/components/SearchableDropdown.vue'
import { translationMixin } from '@/mixins/translationMixin'
import 'vue-search-select/dist/VueSearchSelect.css'
import { empty_guid } from '@/json/data.json'

export default {
  name: 'add-event',
  mixins: [Loader, translationMixin, calendarMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      title: '',
      recurrenceType: null,
      defaultTime: null,
      defaultlocation: null,
      defaultlocationVirtual: null,
      selectLocation: null,
      selectLocationVirtual: null,
      selectedCordinatorForUpdate: null,
      isEditMode: null,
      isEditLoopOn: false,
      isMultiDayEvent: false,
      org_key: null,
      startEndDate: { startDate: null, endDate: null },
      startEndTime: null,
      startTime: null,
      endTime: null,
      weeklyDay: 0,
      defaultRSettings: {
        freq: null,
        config: {
          daily: {
            every: 1
          },
          weekly: {
            every: 1,
            days: []
          },
          monthly: {
            type: null,
            mtype_1: {
              day: 1,
              months: 1
            },
            mtype_2: {
              when: null,
              weekday: null,
              months: 1
            }
          },
          yearly: {
            years: 1,
            type: null,
            ytype_1: {
              month: null,
              day: 1
            },
            ytype_2: {
              when: null,
              weekday: null,
              month: null
            }
          }
        }
      },
      rSettings: {
        freq: null,
        config: {
          daily: {
            every: 1
          },
          weekly: {
            every: 1,
            days: []
          },
          monthly: {
            type: null,
            mtype_1: {
              day: 1,
              months: 1
            },
            mtype_2: {
              when: null,
              weekday: null,
              months: 1
            }
          },
          yearly: {
            years: 1,
            type: null,
            ytype_1: {
              month: null,
              day: 1
            },
            ytype_2: {
              when: null,
              weekday: null,
              month: null
            }
          }
        }
      },
      location: null,
      virtualLocation: null,
      locationTypeSelected: 'physical',
      locationTypeOptions: [],
      errorMessage: null,
      dailyshowstate: false,
      weekshowstate: false,
      monthshowstate: false,
      yearshowstate: false,
      onceshowstate: false,
      validateError: [],
      swap: {
        _dailyshowstate: 'outline-primary',
        _weekshowstate: 'outline-primary',
        _monthshowstate: 'outline-primary',
        _yearshowstate: 'outline-primary',
        _onceshowstate: 'primary'
      },
      coordinator: null,
      coordinators: [],
      event: null,
      eventTitle: null,
      eventType: [{ value: null, text: 'Event Type:' }],
      eventTypeTranslated: {},
      member: null,
      memberType: [{ value: null, text: 'Member Type:' }],
      day: 'Wednesday',
      month: 'January',
      GideonOtherMeetingGuid: '6fd79c51-e6d1-49bd-86f2-a384c7c082b4',
      categories: [], // All categories data
      selectedCategory: null, // Selected category
      selectedEvent: null, // Selected item
      selectedSubItem: null, // Selected subite
      preSelectedCategoryKey: null,
      preSelectedEventKey: null,
      preSelectedDistributionPlacementKey: null,
      isDailyVisible: false,
      isWeeklyVisible: false,
      isMonthlyVisible: false,
      isYearlyVisible: false
    }
  },
  components: {
    'add-meeting-location': AddMeetingLocation,
    'add-meeting-location-virtual': AddMeetingLocationVirtual,
    'custome-location': CustomeLocationSearch,
    gBackground: gBackground,
    LocationSearch,
    LocationVirtualSearch,
    ModelSelect,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    'searchable-dropdown': SearchableDropdown
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus'
    }),
    restrictToNumber(event) {
      // Allow only numeric keys and prevent all others
      const key = event.key
      if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Tab') {
        event.preventDefault()
      }
    },

    handleInput(event) {
      this.eventTitle = event.trim() // Trim the value as you set it
    },

    handleLocationSelect(location) {
      this.location = location

      this.selectLocation = null
      this.defaultlocation = null
      this.selectLocationVirtual = null
      this.defaultlocationVirtual = null
      this.virtualLocation = null
    },
    handleVirtualLocationSelect(location) {
      this.virtualLocation = location

      this.selectLocationVirtual = null
      this.defaultlocationVirtual = null
      this.selectLocation = constants.virtual_location_org_key
      this.location = null
    },
    markSelected(type) {
      this.swap._dailyshowstate = type === 'daily' ? 'primary' : 'outline-primary'
      this.swap._onceshowstate = type === 'once' ? 'primary' : 'outline-primary'
      this.swap._yearshowstate = type === 'yearly' ? 'primary' : 'outline-primary'
      this.swap._weekshowstate = type === 'weekly' ? 'primary' : 'outline-primary'
      this.swap._monthshowstate = type === 'monthly' ? 'primary' : 'outline-primary'
    },
    setProps(obj, newVal) {
      for (var i in obj) {
        if (typeof obj[i] === 'object' && !(obj[i] instanceof Array)) {
          this.setProps(obj[i], newVal)
        } else {
          obj[i] = newVal
        }
      }
    },
    clearType(type) {
      this.setProps(this.rSettings.config.monthly, null)
      this.rSettings.config.monthly.type = type
    },
    clearTypeYear(type) {
      this.setProps(this.rSettings.config.yearly, null)
      this.rSettings.config.yearly.type = type
    },
    changereoccurstate(state) {
      // Reset weeklyDay every time the tab is switched
      this.weeklyDay = [] // Reset the checkbox selection

      this.setProps(this.rSettings, null)
      this.rSettings.freq = state

      if (state === 'monthly' && this.$refs['monthly']) {
        this.rSettings.config.monthly.type = 'type_1'
      } else if (state === 'yearly' && this.$refs['yearly']) {
        this.rSettings.config.yearly.type = 'type_1'
      }
      if (state === 'once') {
        this.$refs['once']
        this.dailyshowstate = false
        this.weekshowstate = false
        this.monthshowstate = false
        this.yearshowstate = false
      } else {
        this.dailyshowstate = state === 'daily' && this.$refs['daily']
        this.weekshowstate = state === 'weekly' && this.$refs['weekly']
        this.monthshowstate = state === 'monthly' && this.$refs['monthly']
        this.yearshowstate = state === 'yearly' && this.$refs['yearly']
      }
      if (this.$refs[state]) {
        this.markSelected(state)
      }
    },

    //For Getting Values For Camp Calendar Event Dropdowns
    async getValuesForCampEvents() {
      try {
        const res = await calendarService.CampCalendarEventsDropdownList(this.prefCulture)
        if (res.data) {
          this.categories = res.data.EventDetails
          // Set default category and item based on user request
          this.setDefaultCategorySelections()
        } else {
          console.error('No data received from the camp events API')
        }
      } catch (err) {
        console.error('Error fetching camp events:', err)
      }
    },

    // Handle category change
    onCategoryChange() {
      this.selectedEvent = null // Reset selected item when category changes
      this.selectedSubItem = null // Reset selected subitem when category changes
      this.setDefaultEventForCategory()
    },

    // Handle item change
    onEventChange() {
      this.selectedSubItem = null // Reset selected subitem when item changes
    },

    // Set default category and event based on specific conditions
    setDefaultCategorySelections() {
      // Find and set "Meeting" or 'prefilled Category' as default category
      let defaultCategory

      if (!this.isEditLoopOn) {
        defaultCategory = this.categories.find(cat => cat.DisplayName === 'tcMeetingEvent')
      } else {
        const upperCasepreSelectedCategory = this.preSelectedCategoryKey.toUpperCase()
        defaultCategory = this.categories.find(cat => cat.Key === upperCasepreSelectedCategory)
      }

      if (defaultCategory) {
        this.selectedCategory = defaultCategory // Set selectedCategory
        this.setDefaultEventForCategory() // Set the default Event for selectedCategory
      }
    },

    // Set default Event based on the selected category
    setDefaultEventForCategory() {
      if (!this.selectedCategory) return

      // Based on the DisplayName of the selected category, set a default event
      let defaultEvent = null
      let defaultDistributionPlacement = null

      let uppercaseKey = null
      if (this.preSelectedEventKey) {
        uppercaseKey = this.preSelectedEventKey.toUpperCase()
      }

      switch (this.selectedCategory.DisplayName) {
        case 'tcMeetingEvent':
          if (!this.isEditLoopOn) {
            defaultEvent = this.selectedCategory.EventList.find(
              event => event.DisplayName === 'tcEventMeetingCampMeeting'
            )
          } else {
            defaultEvent = this.selectedCategory.EventList.find(event => {
              return event.Key === uppercaseKey
            })
          }

          break

        case 'tcEvent':
          if (!this.isEditLoopOn) {
            defaultEvent = this.selectedCategory.EventList.find(
              event => event.DisplayName === 'tcEventAnnualMembershipDinner'
            )
          } else {
            defaultEvent = this.selectedCategory.EventList.find(event => {
              return event.Key === uppercaseKey
            })
          }

          break

        case 'tcFieldActivityEvent':
          if (!this.isEditLoopOn) {
            defaultEvent = this.selectedCategory.EventList.find(
              event => event.DisplayName === 'tcEventFieldChurchRelationsDay'
            )
          } else {
            defaultEvent = this.selectedCategory.EventList.find(event => {
              return event.Key === uppercaseKey
            })

            if (this.preSelectedDistributionPlacementKey) {
              const uppercaseDisKey = this.preSelectedDistributionPlacementKey.toUpperCase()
              defaultDistributionPlacement = defaultEvent.SubEventList.find(subEvent => {
                return subEvent.Key === uppercaseDisKey
              })
            }
          }

          break

        // Add additional cases if necessary
        default:
          console.warn('No default event found for the selected category.')
          break
      }

      if (defaultEvent) {
        this.selectedEvent = defaultEvent
        this.selectedSubItem = defaultDistributionPlacement
        this.isEditLoopOn = false
      }
    },

    //For calculating number of days. month and year
    calculateDaysBetween(startDate, endDate) {
      if (!startDate || !endDate) return { days: 0, weeks: 0, months: 0 }
      const start = new Date(startDate)
      const end = new Date(endDate)
      const timeDifference = end - start
      const days = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1 // +1 to include the end date
      const weeks = Math.floor(days / 7)

      const months = Math.floor(days / 30) // 30 days for each month
      return { days, weeks, months }
    },

    // This method uses calculateDaysBetween
    daysBetween() {
      return this.startEndDate.startDate && this.startEndDate.endDate
        ? this.calculateDaysBetween(this.startEndDate.startDate, this.startEndDate.endDate)
        : { days: 0, weeks: 0, months: 0 } // Return 0 if either date is null
    },

    isVisibleTab(freq) {
      switch (freq) {
        case 'once':
          return true
        case 'daily':
          return this.isDailyVisible
        case 'weekly':
          return this.isWeeklyVisible
        case 'monthly':
          return this.isMonthlyVisible
        case 'yearly':
          return this.isYearlyVisible
        default:
          return false
      }
    },

    // Calling this method on date change
    handleFrequencyOnDateChange() {
      const days = this.daysBetween().days
      this.isDailyVisible = days >= 2
      this.isWeeklyVisible = days >= 7
      this.isMonthlyVisible = days >= 30
      this.isYearlyVisible = days >= 365

      // If current tab is not visible, switch to the next available tab
      if (!this.isVisibleTab(this.rSettings.freq)) {
        if (this.isYearlyVisible) {
          this.changereoccurstate('yearly')
        } else if (this.isMonthlyVisible) {
          this.changereoccurstate('monthly')
        } else if (this.isWeeklyVisible) {
          this.changereoccurstate('weekly')
        } else if (this.isDailyVisible) {
          this.changereoccurstate('daily')
        } else {
          // Reset to default if no frequencies are available
          this.changereoccurstate('once')
        }
      }
    },

    getEventCalenderSetting(org_key) {
      this.showLoader()
      calendarService
        .getEventCalenderFormSettings(org_key, 'camp')
        .then(res => {
          //populate member type
          this.memberType = [
            ...this.memberType,
            ...res.data.MemberTypeList.map(item => {
              return {
                value: item.vmpt_mpt_key,
                text: this.translate(item.vmpt_trn_txt)
              }
            })
          ]
          this._memberType = this.memberType

          // populate cordinator list
          this.coordinators = [
            ...this.coordinators,
            ...res.data.CoordinatorList.sort((a, b) => {
              let alpha = a.sort_name.toUpperCase()
              let beta = b.sort_name.toUpperCase()
              return alpha < beta ? -1 : alpha > beta ? 1 : 0
            }).map(item => {
              return { value: item.ind_key, text: item.name }
            })
          ]
          this.showCordinator = true
          let { aid = null } = this.$router.currentRoute.params
          if (aid !== null) {
            this.eventTitle = this.eventType.find(x => x.value === aid).text
          }
        })
        .catch(err => {})
        .finally(() => {
          this.closeLoader()
        })
    },
    async applyNewLocation(evt) {
      this.selectLocation = evt.key
      this.location = null
      this.defaultlocation = evt.name
      this.selectLocationVirtual = null
      this.virtualLocation = null
    },
    async applyNewLocationVirtual(evt) {
      this.selectLocationVirtual = evt.key
      this.virtualLocation = null
      this.defaultlocationVirtual = evt.name
      this.selectLocation = constants.virtual_location_org_key
      this.location = null
    },
    setLocationTypeOptions() {
      this.locationTypeOptions = [
        {
          text: this.translations.tcPhysicalLocation ? this.translations.tcPhysicalLocation : 'Physical Location',
          value: 'physical'
        },
        {
          text: this.translations.tcVirtualLocation ? this.translations.tcVirtualLocation : 'Virtual Location',
          value: 'virtual'
        }
      ]
    },

    isScriptureEvent() {
      const distributionEvents = [
        'tcEventFieldDistributionEducationalInstitution',
        'tcEventFieldDistributionPenalInstitutions',
        'tcEventFieldDistributionMedicalPersonnel',
        'tcEventFieldDistributionMilitaryResponders',
        'tcEventFieldPlacementOvernightAccommodations'
      ]

      // Check if any event in distributionEvents is included in DisplayName
      return distributionEvents.some(event => this.selectedEvent.DisplayName.includes(event))
    },

    SaveMeetings() {
      this.rSettings.config.weekly.days = this.weeklyDay
      this.validateError = []

      this.validateEventInformation()
      if (this.validateError.length > 0) {
        this.$swal({
          icon: 'error',
          text: `${this.translations.tcErrorAllFieldsRequired} '${this.validateError.join(', ')}'`,
          confirmButtonText: this.translations.tcOk || 'Ok'
        }).then(result => {
          return
        })
      } else {
        let addNewMeetings = {
          start_date: this.startEndDate.startDate,
          end_date: this.startEndDate.endDate,
          mtg_title: this.eventTitle,
          mtg_start_time: this.startTime ? this.startTime : '08:00:00',
          mtg_end_time: this.endTime ? this.endTime : '20:00:00',
          mtg_coordinator_ind_key: this.coordinator,
          mtg_location_org_key: !this.location ? this.selectLocation : this.location.org_key,
          mtg_location_virtual_key: !this.virtualLocation ? this.selectLocationVirtual : this.virtualLocation.lvt_key,
          mtg_mpt_key: this.member,
          org_key: this.userCampKey,
          freq: this.isMultiDayEvent ? this.rSettings.freq : this.defaultRSettings.freq,
          config: this.isMultiDayEvent ? this.rSettings.config : this.defaultRSettings.config,
          mtg_category_key: this.selectedCategory.Key,
          mtg_mtt_key: this.selectedEvent.Key,
          mtg_distribution_placement_key: this.selectedSubItem ? this.selectedSubItem.Key : null,
          is_scripture_event: this.isScriptureEvent()
        }
        this.showLoader()
        calendarService
          .addNewEvent(addNewMeetings)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ path: '/calendar/calendar-month' })
            } else {
              this.$swal({
                text: this.translations.tcErrorOperationFailed,
                icon: 'error',
                confirmButtonText: this.translations.tcOk || 'Ok'
              })
            }
            this.closeLoader()
          })
          .catch(err => {
            this.closeLoader()
          })
      }
    },
    addLocation() {
      this.$root.$emit('bv::show::modal', 'addMeetingLocationModal')
    },
    addLocationVirtual() {
      this.$root.$emit('bv::show::modal', 'addMeetingLocationVirtualModal')
    },
    async getRecurrenceMeetingById(mtr_key) {
      await calendarService.getMeetingRecurrence(mtr_key).then(res => {
        this.eventTitle = res.data.mtr_title
        this.member = res.data.mtr_mpt_key

        this.preSelectedCategoryKey = res.data.mtr_category_key
        this.preSelectedEventKey = res.data.mtr_mtt_key
        this.preSelectedDistributionPlacementKey = res.data.mtr_distribution_placement_key

        this.coordinator = res.data.mtr_coordinator_ind_key
        this.selectLocation = res.data.mtr_location_org_key
        this.startEndDate = {
          startDate: this.formatForDatePicker(res.data.mtr_recurrence_date_range_begin_date),
          endDate: this.formatForDatePicker(res.data.mtr_recurrence_date_range_end_date)
        }
        this.startTime = res.data.mtr_recurrence_date_range_begin_date.split('T')[1]
        this.endTime = res.data.mtr_recurrence_date_range_end_date.split('T')[1]
        this.defaultTime = {
          startTime: res.data.mtr_recurrence_date_range_begin_date.split('T')[1],
          endTime: res.data.mtr_recurrence_date_range_end_date.split('T')[1]
        }
        this.changereoccurstate(res.data.vmrt_trn_txt.toLowerCase())
        this.setUpEditInfo(res.data.vmrt_trn_txt.toLowerCase(), res.data)
        this.isMultiDayEvent = this.startEndDate.startDate === this.startEndDate.endDate ? false : true
        this.defaultlocation = res.data.org_name
        this.defaultlocationVirtual = res.data.lvt_name
        this.selectLocationVirtual = res.data.mtr_location_virtual_key
        if (this.selectLocationVirtual) {
          this.locationTypeSelected = 'virtual'
        }
      })
    },
    getMeetingById(org_key, mtg_key) {
      calendarService.getMeetingSingle(org_key, mtg_key).then(res => {
        this.eventTitle = res.data.meetings[0].mtg_title
        this.member = res.data.meetings[0].mtg_mpt_key
        this.coordinator = res.data.meetings[0].mtg_coordinator_ind_key
        this.selectLocation = res.data.meetings[0].mtg_location_org_key
        this.startEndDate = {
          startDate: this.formatForDatePicker(res.data.meetings[0].mtg_start_date),
          endDate: this.formatForDatePicker(res.data.meetings[0].mtg_end_date)
        }
        this.startTime = res.data.meetings[0].mtg_start_date.split('T')[1]
        this.endTime = res.data.meetings[0].mtg_end_date.split('T')[1]
        this.defaultTime = {
          startTime: res.data.meetings[0].mtg_start_date.split('T')[1],
          endTime: res.data.meetings[0].mtg_end_date.split('T')[1]
        }
        this.defaultlocation = res.data.meetings[0].location
        this.defaultlocationVirtual = res.data.meetings[0].lvt_name
        this.selectLocationVirtual = res.data.meetings[0].lvt_key

        this.preSelectedCategoryKey = res.data.meetings[0].mtg_category_key
        this.preSelectedEventKey = res.data.meetings[0].mtg_mtt_key
        this.preSelectedDistributionPlacementKey = res.data.meetings[0].mtg_distribution_placement_key

        if (this.selectLocationVirtual) {
          this.locationTypeSelected = 'virtual'
        }
      })
    },
    setUpEditInfo(type, data) {
      switch (type) {
        case 'weekly':
          this.rSettings.config.weekly.every = data.mtr_recurrence_day_of_week_or_month
          if (!!data.mtr_recurrence_specific_days_of_week) {
            this.rSettings.config.weekly.days = data.mtr_recurrence_specific_days_of_week.split(',')
            this.weeklyDay = data.mtr_recurrence_specific_days_of_week.split(',')
          }
          break
        case 'daily':
          this.rSettings.config.daily.every = data.mtr_recurrence_frequency
          break
        case 'monthly':
          this.rSettings.config.monthly.type = data.mtr_option
          if (data.mtr_option === 'type_1') {
            this.rSettings.config.monthly.mtype_1.day = data.mtr_recurrence_frequency
            this.rSettings.config.monthly.mtype_1.months = data.mtr_recurrence_month_frequency
          } else {
            this.rSettings.config.monthly.mtype_2.when = data.mtr_recurrence_frequency
            this.rSettings.config.monthly.mtype_2.weekday = parseInt(data.mtr_recurrence_day_of_week_or_month)
            this.rSettings.config.monthly.mtype_2.months = data.mtr_recurrence_month_frequency
          }
          break
        case 'yearly':
          this.rSettings.config.yearly.type = data.mtr_option
          this.rSettings.config.yearly.years = data.mtr_recurrence_year_frequency
          if (data.mtr_option === 'type_1') {
            this.rSettings.config.yearly.ytype_1.day = data.mtr_recurrence_frequency
            this.rSettings.config.yearly.ytype_1.month = data.mtr_recurrence_month_frequency
          } else {
            this.rSettings.config.yearly.ytype_2.when = data.mtr_recurrence_frequency
            this.rSettings.config.yearly.ytype_2.month = data.mtr_recurrence_month_frequency
            this.rSettings.config.yearly.ytype_2.weekday = data.mtr_recurrence_day_of_week_or_month
          }
          break
      }
    },
    UpdateMeetings() {
      this.rSettings.config.weekly.days = this.weeklyDay
      this.validateError = []
      this.validateEventInformation()

      if (this.validateError.length < 0) {
        this.$swal({
          icon: 'error',
          text: `${this.translations.tcErrorAllFieldsRequired} '${this.validateError.join(', ')}'`,
          confirmButtonText: this.translations.tcOk || 'Ok'
        }).then(result => {
          return
        })
      } else {
        let UpdateMeetings = {
          start_date: this.startEndDate.startDate,
          end_date: this.startEndDate.endDate,
          mtg_title: this.eventTitle,
          mtg_start_time: this.startTime ? this.startTime : '08:00:00',
          mtg_end_time: this.endTime ? this.endTime : '20:00:00',
          mtg_coordinator_ind_key: this.coordinator,
          mtg_location_org_key: !this.location
            ? this.selectLocation === empty_guid
              ? null
              : this.selectLocation
            : this.location.org_key,
          mtg_location_virtual_key: !this.virtualLocation ? this.selectLocationVirtual : this.virtualLocation.lvt_key,
          mtg_mpt_key: this.member,
          mtg_category_key: this.selectedCategory.Key,
          mtg_mtt_key: this.selectedEvent.Key,
          mtg_distribution_placement_key: this.selectedSubItem ? this.selectedSubItem.Key : null,
          org_key: this.user.user.camp_key,
          freq: this.rSettings.freq !== 'once' ? this.rSettings.freq : this.defaultRSettings.freq,
          config: this.rSettings.freq !== 'once' ? this.rSettings.config : this.defaultRSettings.config,
          mtg_category_key: this.selectedCategory.Key,
          mtg_distribution_placement_key: this.selectedSubItem ? this.selectedSubItem.Key : null,
          is_scripture_event: this.isScriptureEvent(),
          is_update: {
            mtr_key: this.$router.currentRoute.params.id
          }
        }
        if (!!this.$router.currentRoute.params.mtg_key) {
          UpdateMeetings.mtg_key = this.$router.currentRoute.params.mtg_key
        }
        this.showLoader()
        calendarService
          .addNewEvent(UpdateMeetings)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ path: '/calendar/calendar-month' })
            } else {
              this.$swal({
                text: this.translations.tcErrorOperationFailed,
                icon: 'error',
                confirmButtonText: this.translations.tcOk || 'Ok'
              })
            }
            this.closeLoader()
          })
          .catch(err => {
            this.closeLoader()
          })
      }
    },

    validateEventInformation() {
      if (!this.member) {
        this.validateError.push(this.translate('Member Type'))
      }
      if (this.eventTitle === null || this.eventTitle.trim() === '' || this.eventTitle.length <= 0) {
        this.validateError.push(this.translate('Event Title'))
      }

      if (this.selectedCategory === '' || this.selectedCategory === null) {
        this.validateError.push('Category ')
      }

      if (this.selectedEvent === '' || this.selectedEvent === null) {
        this.validateError.push('Event')
      }

      if (!!this.startEndDate.startDate && !this.isMultiDayEvent) {
        this.startEndDate.endDate = this.startEndDate.startDate
      }

      if (!this.startEndDate || !this.startEndDate.startDate || !this.startEndDate.endDate) {
        if (!this.isMultiDayEvent) {
          this.validateError.push(this.translate('Date'))
        } else {
          this.validateError.push(this.translate('Start And End Date'))
        }
      }

      if (
        !!this.startEndDate.startDate &&
        !!this.startEndDate.endDate &&
        this.startEndDate.endDate < this.startEndDate.startDate
      ) {
        this.validateError.push('End Date cannot be before Start Date')
      }

      if (!this.startTime && this.endTime) {
        this.validateError.push('End Time Cannot be without Start Time')
      }

      if (this.startTime && this.endTime) {
        const startTimeParts = this.startTime.split(':').map(Number)
        const endTimeParts = this.endTime.split(':').map(Number)

        const startDateTime = new Date(0, 0, 0, startTimeParts[0], startTimeParts[1], startTimeParts[2])
        const endDateTime = new Date(0, 0, 0, endTimeParts[0], endTimeParts[1], endTimeParts[2])

        if (endDateTime <= startDateTime) {
          this.validateError.push('End time must be after start time.')
        }
      }

      const startDate = this.startEndDate.startDate // Adjust as needed
      const endDate = this.startEndDate.endDate // Adjust as needed
      const allowedDays = this.calculateDaysBetween(startDate, endDate) // Get allowed days

      if (this.isMultiDayEvent) {
        switch (this.rSettings.freq) {
          case 'daily':
            if (
              !this.rSettings.config.daily ||
              !this.rSettings.config.daily.every ||
              this.rSettings.config.daily.every < 1
            ) {
              this.validateError.push(this.translate('Dayss')) // `Dayss` is not a typo, it translates to 'Day(s)'
            } else if (this.rSettings.config.daily.every > allowedDays.days) {
              this.validateError.push('Daily input value should not exceed Start and End date Range.')
            } else if (this.rSettings.config.daily.every > 365) {
              this.validateError.push('Daily input value max limit is 365 days.')
            }

            break
          case 'weekly':
            if (
              !this.rSettings.config.weekly ||
              !this.rSettings.config.weekly.every ||
              this.rSettings.config.weekly.every < 1
            ) {
              this.validateError.push(this.translate('Every Number Weeks')) // Every # Week(s)
            }
            if (!this.rSettings.config.weekly || !this.rSettings.config.weekly.days) {
              this.validateError.push(this.translate('Dayss')) // `Dayss` is not a typo, it translates to 'Day(s)'
            }
            // Check if weekly.every exceeds allowed weeks
            if (this.rSettings.config.weekly.every > allowedDays.weeks) {
              this.validateError.push('Weekly input should not exceed the total weeks between Start and End date range')
            }
            if (this.rSettings.config.weekly.every > 52) {
              this.validateError.push('Max Weekly input is 52 weeks.')
            }

            break
          case 'monthly':
            if (this.rSettings.config.monthly.type === 'type_1') {
              const specifiedDay = this.rSettings.config.monthly.mtype_1.day

              const specifiedMonths = this.rSettings.config.monthly.mtype_1.months

              if (!this.rSettings.config.monthly.mtype_1 || !specifiedDay || specifiedDay < 1) {
                this.validateError.push(this.translate('Day'))
              }
              if (
                !this.rSettings.config.monthly.mtype_1 ||
                !this.rSettings.config.monthly.mtype_1.months ||
                this.rSettings.config.monthly.mtype_1.months < 1
              ) {
                this.validateError.push(this.translate('Months')) // Month(s)
              }

              // New validation: Check if specified months exceed total months in range
              if (specifiedMonths > 12) {
                this.validateError.push(`The value for months exceeds. Max allowed is 12 months.`)
              } else if (specifiedMonths > allowedDays.months) {
                this.validateError.push(
                  `The value for months exceeds the months in the range. Max allowed is ${allowedDays.months}.`
                )
              }

              // Validation logic for specified day based on total days
              if (allowedDays.days === 30 && specifiedDay > 30) {
                this.validateError.push('Incorrect day selection, Max is 30.')
              } else if (allowedDays.days > 31 && specifiedDay > 31) {
                this.validateError.push('Incorrect day selection, max no. days is 31.')
              } else if (allowedDays.days < specifiedDay) {
                this.validateError.push('Incorrect day selection, exceeds the range.')
              }
            }

            if (this.rSettings.config.monthly.type === 'type_2') {
              if (!this.rSettings.config.monthly.mtype_2 || !this.rSettings.config.monthly.mtype_2.when) {
                this.validateError.push(this.translate('Week'))
              }
              if (!this.rSettings.config.monthly.mtype_2 || this.rSettings.config.monthly.mtype_2.weekday == null) {
                this.validateError.push(this.translate('Weekday'))
              }
              if (
                !this.rSettings.config.monthly.mtype_2 ||
                !this.rSettings.config.monthly.mtype_2.months ||
                this.rSettings.config.monthly.mtype_2.months < 1
              ) {
                this.validateError.push(this.translate('Months')) // Month(s)
              }
            }

            break
          case 'yearly':
            if (!this.rSettings.config.yearly.years || this.rSettings.config.yearly.years < 1) {
              this.validateError.push(this.translate('Recur Every Number Years')) // Recur Every # Year(s)
            }

            if (this.rSettings.config.yearly.years > 1) {
              this.validateError.push('Max Limit is 1 year.')
            }

            if (this.rSettings.config.yearly.type === 'type_1') {
              if (!this.rSettings.config.yearly.ytype_1 || !this.rSettings.config.yearly.ytype_1.month) {
                this.validateError.push(this.translate('Month'))
              }
              if (
                !this.rSettings.config.yearly.ytype_1 ||
                !this.rSettings.config.yearly.ytype_1.day ||
                this.rSettings.config.yearly.ytype_1.day < 1
              ) {
                this.validateError.push(this.translate('Day'))
              }

              const monthDays = [
                31,
                28, // Assuming no leap year; adjust as needed
                31,
                30,
                31,
                30,
                31,
                31,
                30,
                31,
                30,
                31
              ]

              const month = this.rSettings.config.yearly.ytype_1.month
              const maxDays = monthDays[month - 1]

              if (this.rSettings.config.yearly.ytype_1.day > maxDays) {
                this.validateError.push(`Maximum number of days in selected month are ${maxDays}.`)
              }
            }
            if (this.rSettings.config.yearly.type === 'type_2') {
              if (!this.rSettings.config.yearly.ytype_2 || !this.rSettings.config.yearly.ytype_2.when) {
                this.validateError.push(this.translate('Week'))
              }
              if (!this.rSettings.config.yearly.ytype_2 || this.rSettings.config.yearly.ytype_2.weekday == null) {
                this.validateError.push(this.translate('Weekday'))
              }
              if (!this.rSettings.config.yearly.ytype_2 || !this.rSettings.config.yearly.ytype_2.month) {
                this.validateError.push(this.translate('Month'))
              }
            }

            break
        }
      }
    },
    cancel() {
      this.$router.push({ path: '/calendar/calendar-month' })
    },
    translate(text) {
      // helper function to shorten a translation lookup
      // text should be a value that exists in `this.translations`, IE 'monday', 'january', 'third'
      // This method will convert the `text` into something like `tcMonday`, `tcJanuary`, `tcThird`
      return this.translations[this.convertValForTranslation(text)]
    }
  },
  watch: {
    rSettings: {
      handler: function(value, oldvalue) {},
      deep: true
    },
    coordinator: function(val, old) {}
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userCountryKey: 'user/userCountryKey'
    }),
    days() {
      return date_data.days.map(dd => {
        return { value: dd.weekday, text: this.translate(dd.text) }
      })
    },
    months() {
      return date_data.months.map(mm => {
        return { value: mm.value, text: this.translate(mm.text) }
      })
    },

    ordinal() {
      return date_data.ordinal.map(o => {
        return { value: o.value, text: this.translate(o.text) }
      })
    },
    locationDisplay() {
      if (!this.location && !this.defaultlocation) {
        return this.translations.tcNoLocationSelected || 'No Location Selected'
      } else if (!!this.location && !!this.location.org_name) {
        return this.location.org_name
      } else if (this.defaultlocation === 'Virtual Meeting') {
        return 'No Location Selected'
      } else {
        return this.defaultlocation
      }
    },
    locationVirtualDisplay() {
      if (!this.virtualLocation && !this.defaultlocationVirtual) {
        return this.translations.tcNoLocationSelected || 'No Location Selected'
      } else if (!!this.virtualLocation && !!this.virtualLocation.lvt_name) {
        return this.virtualLocation.lvt_name
      } else {
        return this.defaultlocationVirtual
      }
    },
    // Compute the options for the category dropdown
    categoryOptions() {
      return this.categories.map(category => ({
        value: category, // Store the full category object
        text: category.ItemName // Use ItemName for the label
      }))
    },

    // Compute the options for the item dropdown based on the selected category
    eventOptions() {
      if (this.selectedCategory) {
        return this.selectedCategory.EventList.map(item => ({
          value: item, // Store the full item object
          text: item.EventName // Use EventName for the label
        }))
      }
      return []
    },

    // Compute the options for the subitem dropdown based on the selected item
    distributionAndPlacementOptions() {
      if (this.selectedEvent && this.selectedEvent.SubEventList) {
        return this.selectedEvent.SubEventList.map(subitem => ({
          value: subitem, // Store the full subitem object
          text: subitem.EventName // Use EventName for the label
        }))
      }
      return []
    },

    calculateDateDifference(startDate, endDate) {
      if (!startDate || !endDate) return { years: 0, months: 0 }

      // Create Date objects from the input
      const start = new Date(startDate)
      const end = new Date(endDate)
      // Calculate the difference in years and months
      let years = end.getFullYear() - start.getFullYear()
      let months = end.getMonth() - start.getMonth()

      // Adjust the years and months if necessary
      if (months < 0) {
        years-- // If months is negative, subtract one year
        months += 12 // Add 12 months to align
      }

      return { years: years, months: months }
    }
  },
  async created() {
    this.setLoadingStatus(true)
    await Promise.all([
      await this.getViewTranslations('buttons', 'date-time', 'forms'),
      await this.getComponentTranslations(
        'add-meeting-location',
        'add-meeting-location-virtual',
        'custome-location-search',
        'location-search',
        'location-virtual-search'
      ),
      await this.getComponentTranslations('common.calendar-event-type'),

      await (async () => {
        if (!!this.$router.currentRoute.params.aid) {
          let { aid } = this.$router.currentRoute.params
          this.preSelectedEventKey = aid
        }
        await this.getEventCalenderSetting(this.user.user.camp_key)
        this.isEditMode = this.$router.currentRoute.params.type
        if (!!this.isEditMode) {
          this.defaultRSettings.freq = 'once'
          //edit
          if (this.isEditMode === 'once' || this.isEditMode === 'single') {
            this.title = this.translations.tcEditEvent
            this.recurrenceType = this.$router.currentRoute.params.type
            await this.getMeetingById(this.user.user.camp_key, this.$router.currentRoute.params.mtg_key)
          } else {
            this.title = this.translations.tcEditEvent
            this.recurrenceType = this.$router.currentRoute.params.type
            await this.getRecurrenceMeetingById(this.$router.currentRoute.params.id)
          }
          // Determine Tab visibility visibility
          this.handleFrequencyOnDateChange()
          this.isEditLoopOn = true
        } else {
          //add
          this.isEditMode = null
          this.title = this.translations.tcAddEvent
          this.rSettings.freq = 'once'
          this.defaultRSettings.freq = 'once'
        }

        await this.getValuesForCampEvents()
      })()
    ]).then(results => {
      const componentTranslations = results[1]
      this.stripReadableText(results[2])
      this.$set(this.translations, 'components', componentTranslations)
      this.eventType[0].text = this.translations.tcEventType + ':'
      this.memberType[0].text = this.translations.tcMemberType + ':'
      this.setLocationTypeOptions()
      this.setLoadingStatus(false)
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

label.custom-control-label {
  width: 230px;
}

.flex-row {
  display: flex;
  align-items: center;
  .custom-control-inline {
    align-items: center;
  }
}
.checkbox-ml {
  margin-left: 11px;
}
.add-template {
  h1 {
    margin-bottom: 34px;
  }

  hr {
    margin: 60px 0 35px;
  }

  @include breakpoint(sm) {
    .page-header {
      text-align: center;

      h1 {
        font-size: 42px;
      }
    }
  }

  .button-group {
    .btn-primary {
      border: 1px solid #003946;
      text-transform: uppercase !important;
    }
  }

  .g-background {
    margin: 90px auto 104px;
  }

  .h-style-1 {
    margin-bottom: 10px;
  }

  .form-block {
    .form-element {
      margin-bottom: 10px;

      &.form-attachment {
        margin-bottom: 60px;
        margin-top: 45px;

        @include breakpoint(sm) {
          margin-top: 25px;
        }
      }

      .custom-checkbox {
        padding-left: 34px;
        line-height: 25px;

        label {
          font-size: 16px;

          &:before {
            left: -34px;
            border-radius: 0;
            width: 24px;
            height: 24px;
            border-color: #636363;
          }

          &:after {
            left: -34px;
            height: 24px;
            width: 24px;
          }
        }

        .custom-control-input:checked ~ .custom-control-label::after {
          background-color: #003946;
        }
      }
    }
  }

  @include breakpoint(sm) {
    .form-buttons {
      .btn {
        margin-bottom: 15px !important;

        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.add-event {
  .btn-group {
    margin-bottom: 20px;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }

  .event-type-form,
  .member-type-form {
    display: flex;
    .custom-select {
      background-position: 95% center, 87% center;
    }
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }

    .w-270 {
      width: 270px !important;
    }
    .custom-select {
      margin-right: 20px;
      width: 200px;

      @include breakpoint(sm) {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        min-width: 200px;
      }
    }

    #event-input {
      width: 340px;

      @include breakpoint(sm) {
        width: 100%;
      }
    }
  }

  .member-type-form {
    margin-bottom: 40px;
  }

  .event-type-form {
    margin-bottom: 10px;

    @include breakpoint(sm) {
      .custom-select {
        margin-bottom: 10px;
      }
    }
  }

  .city-state-zip {
    > div {
      display: flex;

      @include breakpoint(sm) {
        flex-wrap: wrap;
      }

      .form-control {
        flex: 1 1 auto;
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }

        &.custom-select {
          margin-right: 20px;

          @include breakpoint(sm) {
            max-width: 100%;
            margin-right: 0;
          }
        }

        @include breakpoint(sm) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }

  .form-control {
    font-weight: bold;
  }

  .coordinator {
    display: flex;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }

    .form-control {
      margin-right: 20px !important;

      @include breakpoint(sm) {
        margin-right: 0 !important;
        margin-bottom: 10px;
      }

      &.custom-select {
        width: 300px;

        @include breakpoint(sm) {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }

  .form-block {
    margin-bottom: 55px;
  }

  .every-week-on {
    > div {
      display: flex;
      align-items: center;

      .every-input {
        width: 125px;
      }

      span {
        font-weight: bold;
        text-transform: uppercase;

        &:first-of-type {
          margin-right: 10px;
        }

        &:last-of-type {
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      .custom-select {
        width: 180px;
      }
    }
  }
}

.dp-container {
  display: flex;

  .dp-startend {
    width: 50%;

    @include breakpoint(sm) {
      width: 100%;
    }
  }
}

.contact_date {
  width: 100%;

  @include breakpoint(sm) {
    width: 100%;
  }
}
</style>
